import React from 'react';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import styled from 'styled-components';
import { Marker } from './Card';

const MapContainer = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
`;

const Map = ({ height = 500 }: { height?: number }) => (
  <MapContainer style={{ width: '100%', height }}>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyC8F3_JoMaboI5fPBbiUmiMZ3tO3chfSQg',
      }}
      defaultZoom={14}
      defaultCenter={{ lat: 54.363602, lng: 18.582326 }}
    >
      <Marker lat={54.363602} lng={18.582326}>
        <LocationOnIcon fontSize="large" color="primary" />
      </Marker>
    </GoogleMapReact>
  </MapContainer>
);

export default Map;
