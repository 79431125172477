import React, { FC, useState } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import ModalGallery from './ModalGallery';
import { CmsImage, getThumbUrl } from './images';

export interface Photo {
  title: string;
  id: string;
  image: CmsImage;
}

const PhotoTitle = styled.h4`
  padding: 0 1rem;
  font-size: 22px;
  font-weight: 500;
`;

interface FormatterProps {
  caption: string;
  prevIndex: number;
  nextIndex: number;
  totalCount: number;
}

const PhotoCard = styled(Card)`
  margin-bottom: 1rem;
`;

const Gallery: FC<{ photos: Photo[] }> = ({ photos }) => {
  const [current, setCurrent] = useState<number | null>(null);

  return (
    <>
      <Row>
        {photos.map((photo, index) => (
          <Col xs={12} sm={6} lg={4}>
            <PhotoCard key={photo.id}>
              <CardActionArea onClick={() => setCurrent(index)}>
                <CardMedia
                  component="img"
                  alt={photo.title}
                  image={getThumbUrl(photo.image)}
                  title={photo.title}
                />
                <PhotoTitle>{photo.title}</PhotoTitle>
              </CardActionArea>
            </PhotoCard>
          </Col>
        ))}
      </Row>
      <ModalGallery
        photos={photos}
        current={current}
        close={() => setCurrent(null)}
      />
    </>
  );
};

export default Gallery;
