const bg = '#fffffb';
const primary = '#009395';
const primaryDark = '#008486';
const primaryLight = '#4eb4b6';
const secondary = '#dfa178';
const neutral = '#434244';
const secondaryDark = '#51342d';
const neutralLight = 'rgb(224, 224, 224)';

const theme = {
  color: {
    bg,
    primary,
    primaryDark,
    primaryLight,
    secondary,
    secondaryDark,
    neutral,
    neutralLight,
    font: {
      primary: 'hsla(0,0%,0%,0.8)',
    },
  },
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 75, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
};

export default theme;
