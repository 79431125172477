import React from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import Map from './Map';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  justify-content: center;
  margin-bottom: 1rem;
  height: 100%;
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: ${(p) => p.theme.color.neutral};
  color: ${(p) => p.theme.color.bg};
`;

export const Copy = styled.div`
  margin-top: 2rem;
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
`;

const PageFooter = ({ title }: { title: string }) => (
  <Footer>
    <Grid>
      <Row style={{ width: '100%' }}>
        <Col xs={12} md={5}>
          <Content>
            {title}
            <span>ul.Magellana 14</span>
            <a href="tel:+48514647495">514 647 495</a>
            <a href="mailto:info@thebear.pl">info@thebear.pl</a>
          </Content>
        </Col>
        <Col xs={12} mdOffset={1} md={4}>
          <Map height={250} />
        </Col>
        <Col xs={12}>
          <Copy>{`Copyright ${new Date().getFullYear()}`}</Copy>
        </Col>
      </Row>
    </Grid>
  </Footer>
);

export default PageFooter;
