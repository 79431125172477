import React, { FC } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { getUrl } from './images';

interface CmsImage {
  url: string;
}

interface Photo {
  title: string;
  id: string;
  image: CmsImage;
}

interface FormatterProps {
  caption: string;
  prevIndex: number;
  nextIndex: number;
  totalCount: number;
}

const formatters = {
  getAltText: ({ caption }: FormatterProps) => caption,
  getNextLabel: ({ nextIndex, totalCount }: FormatterProps) =>
    `Pokaż zdjęcie ${nextIndex} z  ${totalCount}`, // Show slide {nextIndex} of {totalCount}
  getPrevLabel: ({ prevIndex, totalCount }: FormatterProps) =>
    `Pokaż zdjęcie ${prevIndex} z  ${totalCount}`, // Show slide {nextIndex} of {totalCount}
  getNextTitle: () => 'Następne (strzałka w prawo)', // Next (right arrow)
  getPrevTitle: () => 'Poprzednie (strzałka w lewo)', // Next (right arrow)
  getCloseLabel: () => 'Zamknij (esc)', // Close (esc)
  getFullscreenLabel: () => 'Przełącz tryb pełnoekranowy', // [Enter | Exit] fullscreen (f)
};


const FooterCount: FC<{ currentIndex: number; views: any[] }> = ({
  currentIndex,
  views,
}) => {
  const activeView = currentIndex + 1;
  const totalViews = views.length;

  return <span>{`${activeView} z ${totalViews}`}</span>;
};

const ModalGallery: FC<{
  photos: Photo[];
  current: number | null;
  close: () => void;
}> = ({ current, close, photos }) => {
  return (
    <ModalGateway>
      {current != null && (
        <Modal onClose={close}>
          <Carousel
            currentIndex={current}
            views={photos.map(photo => ({
              source: getUrl(photo.image),
              caption: photo.title,
            }))}
            components={{
              FooterCount,
            }}
            formatters={formatters}
          />
        </Modal>
      )}
    </ModalGateway>
  );
};

export default ModalGallery;
