/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import Helmet from 'react-helmet';
import {
  createMuiTheme,
  ThemeProvider as MuiProvider,
} from '@material-ui/core/styles';

import Header from './Header';
import GlobalStyle from './GlobalStyle';
import theme from '../theme';
import PageFooter from './PageFooter';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.color.primary,
    },
  },
});

const Content = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const BgImg = styled.img`
  width: 100%;
  max-width: 1300px;
  margin-bottom: 0;
  z-index: 1;
  flex: 0;
`;

const BgWrap = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  top: 13rem;
  bottom: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const Wrap = styled.div`
  position: relative;
`;

const BGColor = styled.div`
  /* background: #11918a; */
  /* background: red; */
  width: 100%;
  margin-top: -150px;
  @media (max-width: 1300px) {
    margin-top: -11vw;
  }
  z-index: 0;
  flex: 1;
`;

const Layout: FC<{}> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <MuiProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Helmet>
            <link
              href="https://fonts.googleapis.com/css?family=Roboto:300,400,700&amp;subset=latin-ext"
              rel="stylesheet"
            />
          </Helmet>
          <Wrap>
            <BgWrap>
              <BgImg src="/teddy-bg.svg" alt="bg" />
              <BGColor />
            </BgWrap>
            <Content>
              <Header siteTitle={data.site.siteMetadata.title} />
              <>{children}</>
              <PageFooter title={data.site.siteMetadata.title} />
            </Content>
          </Wrap>
        </>
      </ThemeProvider>
    </MuiProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
