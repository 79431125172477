import React, { FC } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { getTeacherUrl } from './images';
import Content from './Content';

export interface Teacher {
  id: string;
  name: string;
  description: string;
  photo?: {
    url: string;
  };
}

export interface TeachersProps {
  data: Teacher[];
}

const CardRow = styled(Row)`
  margin-bottom: 1.465rem;
`;

const CardContent = styled(Col)`
  padding: 1.5rem;
  p {
    margin-bottom: 1rem;
  }
`;

const TeacherWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 48em) {
    flex-direction: column;
  }
`;

const TeacherPhoto = styled.img`
  padding-right: 1.5rem;
  @media (max-width: 48em) {
    padding-right: 0;
  }
  height: auto;
  width: 300px;
`;

const Wrap = styled.div`
  flex: 1;
`;

const PhotoWrap = styled(Wrap)`
  flex: 1;
  max-width: 300px;
`;

const TeacherHeader = styled.h3<{ first: boolean }>`
  font-weight: 500;
  margin: ${p => (p.first ? 0.5 : 1.5)}rem -1.5rem ${p => (p.hasDescription ? 1.5 : 0)}rem;
  padding: ${p => (p.first ? 0 : 1.5)}rem 1.5rem 0rem;
  border-top: ${p => (p.first ? 0 : 1)}px solid
    ${p => p.theme.color.neutralLight};
`;

const Teachers: FC<TeachersProps> = ({ data }) => (
  <CardRow>
    <CardContent xs={12} as={Paper}>
      {data.map(({ id, name, description, photo }, index) => (
        <React.Fragment key={id}>
          <TeacherHeader first={index === 0} hasDescription={!!description}>
            {name}
          </TeacherHeader>
          <TeacherWrap>
            {photo && (
              <PhotoWrap>
                <TeacherPhoto
                  height="auto"
                  src={getTeacherUrl(photo)}
                  alt={name}
                />
              </PhotoWrap>
            )}
            <Wrap>
              <Content source={description} />
            </Wrap>
          </TeacherWrap>
        </React.Fragment>
      ))}
    </CardContent>
  </CardRow>
);

export default Teachers;
