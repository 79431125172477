import { graphql, useStaticQuery } from 'gatsby';
import { CmsCard } from './components/Card';

export interface Menu {
  slug: string;
  name: string;
  id: string;
  parent?: Menu;
  items?: Menu[];
  cards?: CmsCard[];
}

const useMenus = () => {
  const {
    cms: { menus },
  }: { cms: { menus: Menu[] } } = useStaticQuery(graphql`
    query Menus {
      cms {
        menus(sort: "order") {
          id
          name
          slug
          parent {
            id
          }
          items {
            id
            name
            slug
          }
        }
      }
    }
  `);

  return menus.filter(menu => !menu.parent);
};

export default useMenus;
