import React, { FC } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import MaterialMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Menu } from '../menus';

const NavButton = styled(Button)<{ active?: boolean }>`
  color: ${p => p.theme.color.bg};
  text-transform: none;
  font-size: 14px;
  font-weight: ${p => (p.active ? 800 : 500)};
  margin: 1rem 0.3rem;
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  color: ${p => p.theme.color.font.primary};
`;

const MenuListItem = styled(MenuItem)<{ active?: boolean }>`
  font-weight: ${p => (p.active ? 800 : 500)};
  font-size: 14px;
`;

export const getPath = ({ slug }) => (slug === 'home' ? '/' : `/${slug}`);

// TODO: active
const ExpandableMenu: FC<Menu> = ({ name, slug, items = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const path = getPath({ slug });
  const rect = anchorEl && anchorEl.getBoundingClientRect();
  return (
    <>
      <NavButton
        aria-controls={`${slug}-menu`}
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        endIcon={<ArrowDropDownIcon />}
      >
        {name}
      </NavButton>
      <MaterialMenu
        id={`${slug}-menu`}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorPosition={{
          left: rect ? rect.left : 0,
          top: rect ? rect.bottom : 0,
        }}
        anchorReference="anchorPosition"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map(item => (
          <MenuLink fade to={`${path}/${item.slug}`}>
            <MenuListItem onClick={handleClose}>{item.name}</MenuListItem>
          </MenuLink>
        ))}
      </MaterialMenu>
    </>
  );
};

const MenuButton: FC<Menu> = ({ name, slug, items, ...rest }) => {
  const path = getPath({ slug });
  if (items && items.length) {
    return <ExpandableMenu name={name} slug={slug} items={items} {...rest} />;
  }
  return (
    <MenuLink fade to={path}>
      <NavButton color="primary">{name}</NavButton>
    </MenuLink>
  );
};

export default MenuButton;
