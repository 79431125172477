import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkImages from 'remark-images';
import { getFullUrl } from './images';

const transformUri = uri =>
  uri.startsWith('/') ? getFullUrl({ url: uri }) : uri;

const Content = ({ source }) => (
  <ReactMarkdown
    transformImageUri={transformUri}
    escapeHtml={false}
    linkTarget={() => '_blank'}
    plugins={[[remarkImages, {}]]}
    source={source}
    parserOptions={{ commonmark: true }}
  />
);

export default Content;
