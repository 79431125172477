import { createGlobalStyle } from 'styled-components';
import reset from './reset';

const GlobalStyle = createGlobalStyle`

  ${reset}
  
  html, body {
    padding: 0!important;
    background: #efefef;
    font-size: 1.125rem;
    line-height: 1.8;
    @media (max-width: 48em) {
      font-size: 1rem;
    }
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 1.125rem;
    line-height: 1.8;
    @media (max-width: 48em) {
      font-size: 1rem;
    }
  }
  a {
    color: ${p => p.theme.color.primary};
    text-decoration: none;
    &:hover{
      color: ${p => p.theme.color.primaryLight};
    }

  }
  blockquote {
    border-left: 4px solid ${p => p.theme.color.neutralLight};
    color: ${p => p.theme.color.neutral};
    padding: 1.5rem;
    margin-left: 0;
    font-style: italic;
    p:last-child {
      margin-bottom: 0;
    };
  }
  blockquote:last-child {
    margin-bottom: 0;
  }
`;

export default GlobalStyle;
