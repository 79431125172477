import React, { FC } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { Menu } from '../menus';
import Gallery, { Photo } from './Gallery';
import Teachers, { Teacher } from './Teachers';
import Content from './Content';
import Map from './Map';

export interface CmsCard {
  id: string;
  title: string;
  content: string;
  header: boolean;
  menu: Menu;
  photos: Photo[];
  teachers: Teacher[];
  map: boolean;
}

const CardRow = styled(Row)`
  margin-bottom: 1.465rem;
`;

const CardContent = styled(Col)`
  padding: 1.5rem;
  border-radius: 1rem;
  p {
    margin-bottom: 1rem;
  }
`;

const Title = styled.h2<{ align?: string }>`
  padding: 0.5rem 0;
  font-size: 1.65rem;
  font-weight: 600;
  text-align: ${(p) => p.align || 'center'};
`;

const Header = styled.div`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${(p) => p.theme.color.neutralLight};
`;

export const Marker = styled.div<{ lat: number; lng: number }>`
  margin-left: -20px;
  margin-top: -20px;
  cursor: pointer;
`;

const Card: FC<CmsCard> = ({
  title,
  content,
  header,
  photos,
  map,
  teachers,
}) => (
  <>
    <CardRow>
      <CardContent xs={12} as={Paper}>
        {title && !header && <Title>{title}</Title>}
        {title && header && (
          <Header>
            <Title align="left">{title}</Title>
          </Header>
        )}
        {content && <Content source={content} />}
        {photos && photos.length > 0 && <Gallery photos={photos} />}
        {map && <Map />}
      </CardContent>
    </CardRow>
    {teachers && teachers.length > 0 && <Teachers data={teachers} />}
  </>
);

export default Card;
