import React, { useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getPath } from './MenuItem';

const MenuText = styled(ListItemText)`
  /* span {
    font-size: 0.8rem;
  } */
`;

const Menu = styled(List)`
  min-width: 220px;
`;

const NavItem = ({ name, closeDrawer, slug, items }) => {
  const [open, setOpen] = useState(false);
  const nested = items && items.length > 0;
  const path = getPath({ slug });
  return (
    <>
      <ListItem
        component={!nested ? Link : undefined}
        onClick={nested ? () => setOpen(!open) : closeDrawer}
        fade
        button
        to={path}
      >
        <MenuText primary={name} />
        {nested && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>

      {nested && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {items.map(item => (
              <NavItem
                closeDrawer={closeDrawer}
                key={item.id}
                {...item}
                slug={`${path}/${item.slug}`}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const MobileNav = ({ menus, closeDrawer }) => (
  <Menu component="nav">
    {menus.map(menu => (
      <NavItem closeDrawer={closeDrawer} key={menu.id} {...menu} />
    ))}
  </Menu>
);

export default MobileNav;
