import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Col, Row } from 'react-styled-flexboxgrid';
import Paper from '@material-ui/core/Paper';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/CloseRounded';

import IconButton from '@material-ui/core/IconButton';
import Logo from './Logo';
import useMenus from '../menus';
import MenuItem from './MenuItem';
import MobileNav from './MobileNav';

const HeaderWrapper = styled.div`
  position: relative;
  min-height: 17rem;
`;

const HeaderContainer = styled(Paper)`
  border-radius: 0;
  position: fixed;
  background: ${(p) => p.theme.color.primaryDark};
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
`;

const HeaderCol = styled(Col)`
  background: ${(p) => p.theme.color.primary};
`;

const LogoToggle = styled.div<{ visible: boolean }>`
  max-height: ${(p) => (p.visible ? 9 : 0)}rem;
  height: 9rem;
  transition: 0.2s all ease;
  padding: ${(p) => (p.visible ? 1 : 0)}rem;
`;

const StyledLogo = styled(Logo)`
  max-height: 100%;
`;

const useHeaderScroll = (threshold = 10) => {
  const [value, setValue] = useState(false);
  const onScroll = () => {
    setValue(window.scrollY >= threshold);
  };
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  return value;
};

const Hamburger = styled(IconButton)`
  color: ${(p) => p.theme.color.bg};
`;

const Media = styled.div<{ max?: string; min?: string }>`
  @media (${(p) => (p.max ? 'max' : 'min')}-width: ${(p) => p.max || p.min}) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Header: FC<{ siteTitle: string }> = ({ siteTitle }) => {
  const menus = useMenus();
  const visible = useHeaderScroll();
  const [drawer, setDrawer] = useState(false);
  const closeDrawer = () => setDrawer(false);

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Grid>
          <Row>
            <HeaderCol xs={12}>
              <LogoToggle visible={!visible}>
                <StyledLogo title={siteTitle} />
              </LogoToggle>
              <Media max="48em">
                {menus.map((menu) => (
                  <MenuItem key={menu.id} {...menu} />
                ))}
              </Media>
              <Media min="48em">
                <Hamburger onClick={() => setDrawer(true)}>
                  <MenuIcon />
                </Hamburger>
                <SwipeableDrawer
                  open={drawer}
                  onOpen={() => setDrawer(true)}
                  onClose={closeDrawer}
                >
                  <Left>
                    <IconButton onClick={closeDrawer}>
                      <CloseIcon />
                    </IconButton>
                  </Left>
                  <MobileNav menus={menus} closeDrawer={closeDrawer} />
                </SwipeableDrawer>
              </Media>
            </HeaderCol>
          </Row>
        </Grid>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
