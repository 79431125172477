const HOST = 'https://cms.thebear.pl';

export interface CmsImage {
  url: string;
}

export const getThumbUrl = (image: CmsImage) =>
  `https://res.cloudinary.com/teddy/image/fetch/w_400,h_270,c_fill/${HOST}${image.url}`;

export const getUrl = (image: CmsImage) =>
  `https://res.cloudinary.com/teddy/image/fetch/w_1200,c_fill/${HOST}${image.url}`;

export const getFullUrl = (image: CmsImage) =>
  `https://res.cloudinary.com/teddy/image/fetch/${HOST}${image.url}`;

export const getTeacherUrl = (image: CmsImage) =>
  `https://res.cloudinary.com/teddy/image/fetch/w_300,h_300,c_fill,g_face/${HOST}${image.url}`;

export const getLogoUrl = (image: CmsImage) =>
  `https://res.cloudinary.com/teddy/image/fetch/w_300,c_fill/${HOST}${image.url}`;
